<template>
  <div class="app">
      <header>
        <div class="header-left">
          <a href="#" class="logo">
            <img src="./assets/img/image.png/image.png" alt="Logo">
          </a>
          <nav>
            <a href="#">NAM</a>
            <a href="#">NỮ</a>
            <a href="#">NEW</a>
            <a href="#">BEST</a>
            <a href="#" class="highlight">ƯU ĐÃI</a>
          </nav>
        </div>
        <div class="header-right">
          <div class="search-bar">
            <i class="fas fa-search"></i>
            <input type="text" placeholder="Tìm kiếm">
          </div>
          <div class="icons">
            <a href="#"><i class="fas fa-user"></i></a>
            <a href="#"><i class="fas fa-heart"></i></a>
            <a href="#"><i class="fas fa-shopping-cart"></i></a>
          </div>
        </div>
      </header>
      <div class="slideshow-container-1">
        <div class="slides-wrapper" :style="{ transform: `translateX(-${slideIndex1 * 100}%)` }">
          <div v-for="(slide, index) in slides1" :key="index" class="slide">
            <img :src="slide.img" :alt="slide.alt">
          </div>
        </div>
        <div class="thanhtruot">
          <span
            v-for="(slide, index) in slides1"
            :key="index"
            class="chamtron"
            :class="{ active: index === slideIndex1 }"
            @click="currentSlide1(index)"
          ></span>
        </div>
      </div>
      <div class="img-link">
        <div class="img-link-item">
          <img src="./assets/img/outlet_sale_t824_19-8_1__jpg.webp" alt="Image Link 1">
        </div>
        <div class="img-link-item">
          <img src="./assets/img/outlet_sale_t824_19-8_2__jpg.webp" alt="Image Link 1">
        </div>
        <div class="img-link-item">
          <img src="./assets/img/outlet_sale_t824_20-8_jpg.webp" alt="Image Link 1">
        </div>
      </div>
      <div class="titile-1">
        <h2>Hàng NEW</h2>
      </div>
      <div class="img-link-2">
        <div class="img-link-item-2">
          <img src="./assets/img/shopping (1).webp" alt="Image Link 1">
          <div class="price">
            <h4>áo thể thao</h4>
            <span>250.000Đ</span>
          </div>
        </div>
        <div class="img-link-item-2">
          <img src="./assets/img/shopping (1).webp" alt="Image Link 1">
          <div class="price">
            <h4>áo thể thao</h4>
            <span>250.000Đ</span>
          </div>
        </div>
        <div class="img-link-item-2">
          <img src="./assets/img/shopping (2).webp" alt="Image Link 1">
          <div class="price">
            <h4>áo thể thao</h4>
            <span>250.000Đ</span>
          </div>
        </div>
        <div class="img-link-item-2">
          <img src="./assets/img/shopping (3).webp" alt="Image Link 1">
          <div class="price">
            <h4>áo thể thao</h4>
            <span>250.000Đ</span>
          </div>
        </div>
      </div>
      <div class="footer-container">
        <div class="footer-section">
          <img class="logo" src="./assets/img/image.png/image.png" alt="">
          <p>Shop Thời Trang Manh & Thành</p>
          <p>Văn phòng: tầng 5 Tòa nhà IMC, 62 Trần Quang Khải</p>
        </div>
        <div class="footer-section">
          <h3>CÔNG TY</h3>
          <ul>
            <li><a href="#">Giới thiệu về ROUTINE</a></li>
            <li><a href="#">Tin thời trang</a></li>
            <li><a href="#">In ấn đồng phục</a></li>
            <li><a href="#">Hợp tác nhượng quyền</a></li>
            <li><a href="#">Tuyển dụng</a></li>
          </ul>
        </div>
        <div class="footer-section">
          <h3>CHĂM SÓC KHÁCH HÀNG</h3>
          <ul>
            <li><a href="#">Chính sách khách hàng thân thiết</a></li>
            <li><a href="#">Chính sách đổi trả</a></li>
            <li><a href="#">Chính sách bảo hành</a></li>
            <li><a href="#">Chính sách bảo mật</a></li>
            <li><a href="#">Câu hỏi thường gặp</a></li>
          </ul>
        </div>
        <div class="footer-section">
          <h3>THÔNG TIN CỬA HÀNG</h3>
          <ul>
            <li><a href="#">Cửa hàng thứ 34</a></li>
            <li><a href="#">Cửa hàng thứ 33</a></li>
            <li><a href="#">Cửa hàng thứ 32</a></li>
            <li><a href="#">Xem tất cả các cửa hàng</a></li>
          </ul>
        </div>
        <div class="footer-section social-media">
          <h3>KẾT NỐI VỚI CHÚNG TÔI</h3>
          <a href="#"><i class="fa-brands fa-tiktok"></i></a>
          <a href="#"><i class="fa-brands fa-facebook"></i></a>
          <a href="#"><i class="fa-brands fa-instagram"></i></a>
        </div>
      </div>
  </div>
</template>
  
<script>
  export default {
    data() {
      return {
        slideIndex1: 0,
        slideInterval1: null,
        slides1: [
          { img: require('@/assets/img/images.jpg'), alt: 'Slide 1' },
          { img: require('@/assets/img/banner-thoi-trang-dep.jpg'), alt: 'Slide 2' },
          { img: require('@/assets/img/thiet-ke-khong-ten-14-11-18-04.jpg'), alt: 'Slide 3' },
          { img: require('@/assets/img/banner-thoi-trang-dep.jpg'), alt: 'Slide 4' },
          { img: require('@/assets/img/banner-thoi-trang-dep.jpg'), alt: 'Slide 5' },
          { img: require('@/assets/img/banner-thoi-trang-dep.jpg'), alt: 'Slide 6' }
        ]
      };
    },
    methods: {
      plusSlides1(n) {
        this.slideIndex1 += n;
        if (this.slideIndex1 >= this.slides1.length) {
          this.slideIndex1 = 0;
        } else if (this.slideIndex1 < 0) {
          this.slideIndex1 = this.slides1.length - 1;
        }
      },
      startSlideShow1() {
        this.slideInterval1 = setInterval(() => {
          this.plusSlides1(1);
        }, 5000);
      },
      stopSlideShow1() {
        clearInterval(this.slideInterval1);
      },
      currentSlide1(index) {
        this.stopSlideShow1();
        this.slideIndex1 = index;
        this.startSlideShow1();
      }
    },
    mounted() {
      this.startSlideShow1();
    },
    beforeUnmount() {
      this.stopSlideShow1();
    }
  };
</script>
  
<style scoped>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app{
  max-width: 100%;/**mức chiều rọng cho phép */
  margin: 0 auto;
  width: 1200px;/**chiều rộng mặc định */
  padding-top: 80px; /* Cung cấp không gian cho header cố định */
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 5px;
  background-color: white;
  border-bottom: 1px solid #ccc;
  z-index: 1000; /* Đảm bảo header nằm trên các phần tử khác */
  margin: 0 auto;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo img {
  width: 200px;
  height: 80px;
  margin-right: 20px;
}

nav {
  display: flex;
  justify-content: center; /* Canh giữa các mục nav */
  gap: 20px;
}

nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 0; /* Thêm khoảng cách trên dưới để căn giữa theo chiều dọc */
}


nav a:hover {
  text-decoration: underline;
}

.header-right {
  display: flex;
  align-items: center;
}

nav a.highlight {
  color: red;
}

.search-bar {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-right: 15px;
  border-bottom: 1px solid rgb(210, 210, 210);
}

.search-bar i {
  font-size: 18px;
  color: rgb(240, 102, 28);
  margin-right: 5px;
}
.search-bar input {
  border: none;
  outline: none;
  font-size: 16px;
  width: 200px;
  border-radius: 5px;
}

.icons {
  display: flex;
  gap: 20px;
  font-size: 20px;
  color: black;
}

/**slide-show 1 */
.slideshow-container-1 {
  padding-top: 15px;
  position: relative;
  width: 100%;
  height: 410px;
  margin: auto;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 15px;
}

.slides-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 100%;
}

.slide img {
  border-radius: 5px;
  width: 100%;
  height: 410px;
}

.thanhtruot {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  cursor: pointer;
}

.chamtron {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #e5e3e3;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.active, .chamtron:hover {
  background-color: #000000;
}
/**img-link */
.img-link {
  cursor: pointer;
  padding-top: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
  width: 90%; /* Điều chỉnh phần trăm này theo nhu cầu */
  margin: 0 auto; /* Canh giữa container */
  overflow-x: auto; /* Thêm thanh cuộn ngang nếu cần */
}

.img-link-item {
  flex: 0 0 auto; /* Đảm bảo các item không co lại và không chiếm toàn bộ chiều rộng */
  width: calc(33.33% - 10px); /* Đặt chiều rộng tùy theo số lượng item trong một hàng, đã trừ đi khoảng cách giữa các ảnh */
}

.img-link-item img {
  border-radius: 5px;
  width: 100%; /* Đảm bảo ảnh luôn vừa với container của nó */
  height: auto; /* Duy trì tỉ lệ gốc của ảnh */
}
.titile-1{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.titile-1 p{
  font-weight: 1rem;
  font-size: 16px;
}
.price{

}
/**img-link-2 */
.img-link-2 {
  cursor: pointer;
  padding-top: 15px;
  display: flex;
  justify-content: space-between; /* Chia đều khoảng cách giữa các phần tử */
  gap: 11px;
  width: 100%;
  margin: 0 auto;
  overflow-x: auto; /* Thêm thanh cuộn ngang nếu cần */
}
.img-link-2 img:hover{
  transform: translateY(-1px);
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.05);
}
.img-link-item-2 {
  flex: 1; /* Đảm bảo các item chia đều chiều rộng */
  max-width: calc(25% - 8.25px); /* Đặt chiều rộng tùy theo số lượng item trong một hàng (4 phần tử) */
}

.img-link-item-2 img {
  border-radius: 5px;
  width: 98%;
  height: auto;
  transition: transform ease-in 0.1s;
  will-change: transform;
}

.footer-container {
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #e8e8e8;
  color: black;
  padding: 20px;
  margin-right: 8px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.footer-section h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}
.footer-section .logo{
  width: 200px;
}
.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  color: #000020;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.social-media a {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
  }
  .header-left nav{
    display: none;
  }
  .header-left .logo img {
    padding-left: 50%;
    display: block; /* Đảm bảo logo là một khối nằm giữa */
  }

}

/* Responsive Design */
@media (max-width: 1024px) and (min-width: 768px) {
  header {
    flex-direction: column;
    align-items: center;
  }

  .header-left,
  .header-right {
    margin-bottom: 10px;
  }
  nav {
    flex-direction: row;
    gap: 15px;
    margin-bottom: 10px;
  }

  .search-bar {
    margin-bottom: 10px;
  }
  .icons {
    font-size: 18px;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  
  .slideshow-container-1{
    padding-top: 46px;
  }
  .search-bar input {
    width: 100px;
  }

  .img-link-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    /* padding-right: 10px; */
    /* padding-left: 10px; */
  }
  .img-link-item-2 img {
    width: 171px;
    height: auto; 
    display: block;
    object-fit: cover; 
  }

  nav a {
    font-size: 16px;
  }

  .icons {
    font-size: 18px;
    gap: 15px;
  }
}

@media (max-width: 480px) {
  header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-right {
    width: 95%;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

</style>
